import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { minutes } from '../../time';

/**
 * Find default query options as
 * `QueryObserverOptions` at https://github.com/tannerlinsley/react-query/blob/master/src/core/types.ts
 */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Disabled since we don't rely on realtime data
            refetchOnWindowFocus: false,

            // Time before revalidating data. Set to 5min to
            // match reactQuery's default cache time
            staleTime: minutes(5),
            retry: false,
        },
    },
});

export const RequestProvider = ({ children }: { children: ReactNode }) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};
