import { TranslationKey } from '~/lib/data-contract';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { fetchTranslations } from '~/lib/fetchTranslations';
import { useMarket } from '../../market';

export const generateTranslationQueryKey = ({
    market,
    culture,
    preview,
}: {
    market: string;
    culture: string;
    preview: boolean;
}) => {
    return ['translations', culture?.toLowerCase(), market?.toLowerCase(), preview];
};

/**
 * Translation hook, will load receive initial query data from dehydrated props in getPageProps and fetch new values when culture changes
 */
export const useTranslation = () => {
    const { query } = useRouter();
    const { culture, market } = useMarket();
    const [warnings, setWarnings] = useState<string[]>([]);
    const preview = query.preview === 'true';
    const { data, isLoading } = useQuery(
        generateTranslationQueryKey({
            market,
            culture,
            preview,
        }),
        {
            queryFn: async () =>
                fetchTranslations({
                    culture,
                    market,
                    preview,
                }),
            keepPreviousData: true,
        },
    );

    const translate = (key: TranslationKey, fallback = '') => {
        const translation = data?.[key];
        if (!translation && !warnings.includes(key) && !fallback && typeof window !== 'undefined') {
            console.info(`%cuseTranslation: Missing translation: '${key}'`, 'color: #eed202');
            setWarnings((warnings) => [...warnings, key]);
        }

        return translation ? translation : fallback;
    };

    return { translate, isLoading };
};
